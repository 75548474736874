<template>

    <v-layout row wrap jus>
      <v-flex xl12 lg12 md12 xs12>
        <v-tabs
          height="60"
          centered
          icons-and-text
          background-color="primary"
          slider-color="orange"
          grow
          dark
        >
          <v-tab>
            Busqueda de Abogadas(os)
            <v-icon v-text="'mdi-account'"></v-icon>
          </v-tab>
          <v-tab-item>
            <v-card color="#d1d2d4" class="elevation-0">
              <busqueda-abogado />
            </v-card>
          </v-tab-item>

          <v-tab>
            Busqueda de Sociedades Civiles
            <v-icon v-text="'mdi-account-multiple'"></v-icon>
          </v-tab>
          <v-tab-item>
            <v-card color="#d1d2d4" class="elevation-0">
              <busqueda-sociedad />
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>

</template>
<script>
export default {
  components:{
    "busqueda-abogado":()=>import("@/views/Busqueda/Abogado"),
    "busqueda-sociedad":()=>import("@/views/Busqueda/Sociedad")
  }
};
</script>
<style>
.v-tabs-slider-wrapper  {
    height: 5px !important;
    width: 100%;
}
</style>


